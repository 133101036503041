<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%" v-if="show">
      <v-layout style="text-align: center" justify-center>
        <v-card
          justify-center
          v-html="config.textModefied"
          style="width: 100%; height: 100%; text-align: center"
          elevation="0"
        ></v-card>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { makeDataProvider } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  data: () => ({
    dataProvider: {},
    config: {},
    data: { value: '' },
    show: true,
    columnsInText: null
  }),
  created: function () {
    console.log('========text-display==========', this)
    this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    if (
      this.dataSource &&
      this.dataSource.headers &&
      this.dataSource.headers.length
    ) {
      if (this.dataSource.rows.length) {
        this.dataProvider = this.dataSource.rows
      } else {
        this.dataProvider = makeDataProvider(this.dataSource)
      }
    }
    let allColumnInText = [],
      interpolateText = this.config.text
    let getAllColumns = () => {
      let startMatch = '{{',
        endMatch = '}}'
      if (
        interpolateText.indexOf(startMatch) < 0 ||
        interpolateText.indexOf(startMatch) < 0
      )
        return
      let getOneColumn = () => {
        let startIdx = interpolateText.indexOf(startMatch) + startMatch.length
        let endIdx = interpolateText.indexOf(endMatch)
        return interpolateText.substring(startIdx, endIdx)
      }
      allColumnInText.push(getOneColumn())
      interpolateText = interpolateText.replace(
        startMatch + getOneColumn() + endMatch,
        ''
      )
      if (
        interpolateText.indexOf(startMatch) > -1 &&
        interpolateText.indexOf(endMatch)
      ) {
        getAllColumns()
      } else return allColumnInText
      return allColumnInText
    }
    this.config.textModefied = this.config.text
    if (this.dataProvider.length === 0) {
      //   this.show = false
      console.log({ textInterpolation: 'nothing data has to show' })
    } else if (this.dataProvider.length > 0 && !!this.columnsInText) {
      let hasAllKeys = this.columnsInText.every((column) => {
        return this.dataProvider[0].hasOwnProperty(column)
      })
      if (hasAllKeys) {
        for (let column in this.dataProvider[0])
          this.config.textModefied = this.config.textModefied
            .split('{{' + column + '}}')
            .join(this.dataProvider[0][column])
        this.show = true
      } else {
        // this.show = false
        // console.log(
        //   'All keys are not present in data which is specified in the text',
        //   { columnsInText: this.columnsInText, column: this.config }
        // )
      }
    }
  }
}
</script>